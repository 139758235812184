import React from 'react';
import './Gallery.css';
import image from './image.png';

function Gallery() {
  const nftData = [
    { id: 1, title: 'The Immortal Unleashed: Liverpool’s Dark Watch.', description: 'Standing in front of the historic Liver Building in Liverpool, The Immortal is free and on the move. Batman, Robin, Catwoman, and Riddler form a tense alliance behind him, each with their own motives. In the daylight, the shadows of doubt still linger—will The Immortal rise as a force of good, or succumb to the darkness within?', link: 'https://opensea.io/SkintCuntz' },
    { id: 2, title: 'Liberty’s Clash: The Immortal in New York.', description: 'The Statue of Liberty towers over New York as The Immortal stands at the forefront of a showdown between heroes and villains. Superman and Wonder Woman seek justice, while Lex Luthor and Doomsday plan for domination. The struggle for control unfolds as The Immortal faces his own internal battle—will he join the light or fall into chaos?', link: 'https://opensea.io/SkintCuntz' },
    { id: 3, title: 'London’s Reckoning: The Immortal at Big Ben.', description: 'The hands of Big Ben are ticking as The Immortal stands ready. Behind him, Hulk, Black Widow, Abomination, and Taskmaster prepare for an epic confrontation. With London as their battleground, the clock ticks toward an inevitable clash. The Immortal must choose his path in the heart of the city: will he defend the innocent or unleash havoc?', link: 'https://opensea.io/SkintCuntz' },
    { id: 4, title: 'Lights, Camera, Chaos: the Immortal’s Hollywood Showdown.', description: 'Set against the iconic backdrop of the Hollywood sign, The Immortal finds himself surrounded by chaos. Deadpool, Cable, Juggernaut, and Domino stand ready for action, as the sun blazes over the hills of Los Angeles. The stage is set for a battle like no other, where The Immortal must decide if he’ll play the hero in the limelight or revel in the disorder.', link: 'https://opensea.io/SkintCuntz' },
    // Add more NFT objects as needed
  ];

  return (
    <section id="gallery" className="gallery">
      <h2>Explore the Collection</h2>
      <div className="gallery-grid">
        {nftData.map(nft => (
          <div key={nft.id} className="nft-card">
            <img src={image} alt={nft.title} />
            <div className="nft-info">
              <h3>{nft.title}</h3>
              <p>{nft.description}</p>
              <a 
                href={nft.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="buy-button"
              >
                Buy on OpenSea
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Gallery;
